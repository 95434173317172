import "$styles/index.css";
import "$styles/syntax-highlighting.css";

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}";

import { defineCustomElements } from "packages/component-library/loader";
defineCustomElements();

console.info("Bridgetown is loaded!");
